/*
* @Author: 曹俊杰
* @Date: 2024-09-22 10:05:50
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-10-10 10:54:03
*/ 核查记录
<template>
    <div class="box">

        <el-card shadow="never" :body-style="{ position: 'relative' }" style="margin-top:16px;">
            <div slot="header" style="position: relative;">
                <hdq-section title="排查记录" more-text="" />
            </div>

            <el-table :data="list" tooltip-effect="dark">

                <el-table-column label="核查日期" show-overflow-tooltip width="140px">
                    <template slot-scope="scope">{{ scope.row.createdAt | timeFormat('yyyy-mm-dd hh:MM') }}</template>
                </el-table-column>
                <el-table-column label="姓名" show-overflow-tooltip width="80px">
                    <template slot-scope="scope">{{ scope.row.name }}</template>
                </el-table-column>
                <el-table-column label="手机号" show-overflow-tooltip width="120px">
                    <template slot-scope="scope">{{ scope.row.phone }}</template>
                </el-table-column>
                <el-table-column label="身份证号" show-overflow-tooltip width="160px">
                    <template slot-scope="scope">{{ scope.row.idNumber }}</template>
                </el-table-column>
                <el-table-column label="风险排查内容" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span class="blue">{{ scope.row.content }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80px">
                    <template slot-scope="scope"><span class="blue pointer" @click="detail(scope.row)">查看结果</span></template>
                </el-table-column>

            </el-table>
            <hdq-pagination small ref="pagination" @change="paginationChange" />
        </el-card>
        <InspectDetail ref="InspectDetail" />
    </div>
</template>

<script>
import hdqPagination from "@/components/hdqPagination.vue";
import InspectDetail from "@/views/Inspect/components/InspectDetail.vue"
export default {
    components: {
        hdqPagination,
        InspectDetail
    },
    data() {
        return {
            list: [],
            limit: 600,
            nowTime: Date.parse(new Date()).toString().substr(0, 10),
            text: ''
        };
    },
    mounted() {
        this.getDetectionList();
        // let timer = setInterval(() => {
        //     this.nowTime = Date.parse(new Date()).toString().substr(0, 10);
        // }, 500);
        // this.$once('hook:beforeDestroy', function () {
        //     if (timer) {
        //         clearInterval(timer);
        //         timer = null;
        //     }
        // });
    },
    // filters: {
    //     showtime(nowtime, endtime) {
    //         var lefttime = (endtime * 1000) - (nowtime * 1000),  //距离结束时间的毫秒数
    //             leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)),  //计算天数
    //             lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24),  //计算小时数
    //             leftm = Math.floor(lefttime / (1000 * 60) % 60),  //计算分钟数
    //             lefts = Math.floor(lefttime / 1000 % 60);  //计算秒数
    //         return (leftd && (leftd + "天")) + (lefth && (lefth + "小时")) + (leftm && (leftm + "分钟")) + lefts + '秒';  //返回倒计时的字符串
    //     }
    // },
    methods: {
        async getDetectionList(current = 1, size = 15) {
            console.log(size)
            const { status, data } = await this.$api.getDetectionList({ current, size })
            if (status === 200) {
                const { records } = data
                this.list = records
                this.$refs.pagination.createPagination(data)
            }
        },
        paginationChange({ current, size }) {
            this.getDetectionList(current, size)
        },
        detail({detectionId}) {
            this.$refs.InspectDetail.show(detectionId)
        },
        successClick(scope) {
            const { row: { success, message, fileUrl } } = scope
            if (success) {
                this.$router.push({
                    path: '/inspect/inspectResult',
                    query: {
                        fileUrl: fileUrl
                    }
                })
            } else {
                this.$alert(message, '失败原因', {
                    confirmButtonText: '确定',
                    type: 'error'
                });
            }

        }
    },
};
</script>

<style lang="scss" scoped>
.box {

    .blue {
        color: #0062FF;
    }

    .operate {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        cursor: pointer;

        span:last-child {
            color: #999;
        }
    }
}
</style>
